<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_mAlerts") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_mAlerts") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body
            :style="{ maxHeight: '38vh', overflow: 'auto' }"
            class="px-0"
          >
            <b-card-text class="d-flex mx-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  isEdit = false;
                  modal_record_details = true;
                  clear_data();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <!-- <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button> -->
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :items="items"
              :fields="tableColumns"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(queuename)="data">
                <b-badge variant="light-primary">
                  {{
                    queues.find((e) => e.internal_name == data.value)
                      ? queues.find((e) => e.internal_name == data.value)
                          .display_name
                      : data.value
                  }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <scheduler />
      </b-col>
    </b-row>

    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="setAlert"
    >
      <b-row>
        <b-col>
          <b-form-group :label="lang('t_displayName')">
            <b-form-input
              v-model="alert_info.display_name"
              :placeholder="lang('t_displayName')"
              trim
            /> </b-form-group
        ></b-col>
      </b-row>
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_alertCategory')">
              <v-select
                :placeholder="lang('t_alertCategory')"
                v-model="alert_info.alert_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="alert_categories"
                @input="set_target_types"
              /> </b-form-group
          ></b-col>
          <b-col v-if="alert_info.alert_category == 'agent'">
            <b-form-group :label="lang('t_targetType')">
              <v-select
                multiple
                :placeholder="lang('t_targetType')"
                v-model="alert_info.target_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="(item) => item.identifier"
                :options="agents"
                deselectFromDropdown
                :closeOnSelect="false"
              >
                <template #list-header>
                  <div class="my-50 mx-50">
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          alert_info.target_type = agents.map(
                            (e) => e.identifier
                          );
                        }
                      "
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      {{ lang("t_selectAll") }}
                    </b-button>
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="flat-secondary"
                      @click="
                        () => {
                          alert_info.target_type = [];
                        }
                      "
                    >
                      <feather-icon size="14" icon="XOctagonIcon" />
                      {{ lang("t_clear") }}
                    </b-button>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col v-else>
            <b-form-group :label="lang('t_targetType')">
              <v-select
                multiple
                :placeholder="lang('t_targetType')"
                v-model="alert_info.target_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="target_types"
              /> </b-form-group
          ></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_targetCategory')">
              <v-select
                :placeholder="lang('t_targetCategory')"
                v-model="alert_info.target_category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="
                  target_categories.filter((e) =>
                    e.type.includes(alert_info.alert_category)
                  )
                "
                @input="change_target_category"
              /> </b-form-group
          ></b-col>
          <b-col v-if="alert_info.target_category == 'finish_code'">
            <b-form-group :label="lang('t_target')">
              <v-select
                :placeholder="lang('t_target')"
                v-model="alert_info.target"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="finish_code"
                :reduce="(item) => item.finish_code"
                :options="finish_codes"
              /> </b-form-group
          ></b-col>
          <b-col
            v-else-if="
              ['call_analize', 'ai_call_result', 'ai_call_evaluation'].includes(
                alert_info.target_category
              )
            "
          >
            <b-form-group :label="lang('t_target')">
              <v-select
                :placeholder="lang('t_target')"
                v-model="alert_info.target"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item"
                :options="ai_providers"
              /> </b-form-group
          ></b-col>
          <b-col v-else>
            <b-form-group :label="lang('t_target')">
              <v-select
                :placeholder="lang('t_target')"
                v-model="alert_info.target"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.display_name"
                :options="statuses"
              /> </b-form-group
          ></b-col>
        </b-row>
        <b-row v-if="alert_info.target_category == 'call_analize'">
          <b-col>
            <b-form-group :label="lang('t_speakers')">
              <v-select
                :options="speakers"
                v-model="alert_info.speaker"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang('t_period')">
              <v-select
                :options="periods"
                v-model="alert_info.period"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="alert_info.period != 'automatic'">
            <b-form-group :label="lang('t_periodTime')">
              <b-form-input
                type="number"
                v-model="alert_info.period_value"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="
              ![
                'call_analize',
                'ai_call_result',
                'ai_call_evaluation',
              ].includes(alert_info.target_category)
            "
          >
            <b-form-group :label="lang('t_operator')">
              <v-select
                label="display_name"
                :reduce="(val) => val.internal_name"
                :options="operands"
                v-model="alert_info.operand"
              /> </b-form-group
          ></b-col>
          <b-col>
            <b-form-group
              v-if="
                ['call_analize', 'ai_call_evaluation'].includes(
                  alert_info.target_category
                )
              "
              :label="lang('t_prompt')"
            >
              <b-form-textarea v-model="alert_info.value" rows="3">
              </b-form-textarea>
            </b-form-group>
            <b-form-group
              v-else-if="
                !['ai_call_result'].includes(alert_info.target_category)
              "
              :label="lang('t_value')"
              label-for="t_value"
            >
              <b-form-input autofocus v-model="alert_info.value" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="['call_analize'].includes(alert_info.target_category)">
          <b-col>
            <b-form-group :label="lang('t_exceptions')">
              <b-form-textarea v-model="alert_info.exceptions" rows="3">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="lang('t_communicationType')">
              <v-select
                @input="communication_type_change"
                :options="communication_types"
                v-model="alert_info.communication_type"
                label="display_name"
                :reduce="(item) => item.internal_name"
              /> </b-form-group
          ></b-col>
          <b-col>
            <b-form-group
              v-if="alert_info.communication_type == 'mail'"
              :label="lang('t_communicationSrc')"
              label-for="t_value"
            >
              <v-select
                :options="email_settings"
                v-model="alert_info.communication_src"
                label="display_name"
                :reduce="(item) => item"
              />
            </b-form-group>
            <b-form-group
              v-if="alert_info.communication_type == 'sms'"
              :label="lang('t_communicationSrc')"
              label-for="t_value"
            >
              <v-select
                :options="sms_settings"
                v-model="alert_info.communication_src"
                label="display_name"
                :reduce="(item) => item"
              />
            </b-form-group>

            <b-form-group
              v-if="alert_info.communication_type == 'http'"
              :label="lang('t_type')"
            >
              <v-select
                v-model="alert_info.http_info.type"
                :options="['GET', 'POST', 'PUT', 'DELETE']"
                :placeholder="lang('t_type')"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="alert_info.communication_type == 'http'">
          <b-col>
            <b-form-group :label="lang('t_url')">
              <b-form-input
                v-model="alert_info.http_info.url"
                trim
                :placeholder="lang('t_url')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'sms'">
          <b-col>
            <b-form-group :label="lang('t_rRemoteNumber')">
              <b-form-input
                v-model="alert_info.communication_destination"
                trim
                :placeholder="lang('t_rRemoteNumber')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'mail'">
          <b-col>
            <b-form-group :label="lang('t_to')">
              <b-form-input
                v-model="alert_info.communication_destination"
                trim
                :placeholder="lang('t_to')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type != 'http'">
          <b-col>
            <b-form-group :label="lang('t_yourMessage')">
              <b-form-textarea
                rows="3"
                v-model="alert_info.communication_text"
                trim
                :placeholder="lang('t_yourMessage')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="alert_info.communication_type == 'http'">
          <b-col>
            <b-form-group :label="lang('t_headers')">
              <b-form-textarea
                rows="4"
                v-model="alert_info.http_info.headers"
                trim
                :placeholder="lang('t_headers')"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="lang('t_body')">
              <b-form-textarea
                rows="4"
                v-model="alert_info.http_info.body"
                trim
                :placeholder="lang('t_body')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="
            ['notification', 'mail'].includes(alert_info.communication_type)
          "
        >
          <b-col>
            <b-form-checkbox
              v-model="alert_info.inform_agent"
              class="custom-control-primary"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <!-- <i class="vs-icon feather icon-check" /> -->
                  {{ lang("t_informAgent") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BContainer
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import scheduler from "../../Scheduler/list/List.vue";
import cronParser from "cron-parser";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
globalThis.cronParser = cronParser;
export default {
  components: {
    // UsersListFilters,
    scheduler,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    BContainer,
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      modal_record_details: false,
      cronstrue: "",
      perPage: 10,
      totalUsers: 0,
      perPageOptions: "",
      searchQuery: "",
      sortBy: "",
      isSortDirDesc: "",
      currentPage: 1,
      agents: [],
      projects: [],
      campaigns: [],
      queues: [],
      ivr_list: [],
      finish_codes: [],
      statuses: [],
      target_types: [],
      email_settings: [],
      sms_settings: [],
      ai_providers: [],

      target_categories: [
        {
          type: ['agent', 'queue', 'project'],
          internal_name: "finish_code",
          display_name: globalThis._lang("t_finishCode"),
        },
        {
          type: ['agent'], internal_name: "status", display_name: globalThis._lang("t_status")
        },
        {
          type: ['agent', 'queue', 'project'],
          internal_name: "call_analize",
          display_name: globalThis._lang("t_aiCallAnalize"),
        },
        {
          type: ['agent', 'queue', 'project'],
          internal_name: "ai_call_result",
          display_name: globalThis._lang("t_aiCallResult"),
        },
        {
          type: ['agent', 'queue', 'project'],
          internal_name: "ai_call_evaluation",
          display_name: globalThis._lang("t_aiCallEvaluation"),
        },
      ],
      alert_categories: [
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        {
          internal_name: "project",
          display_name: globalThis._lang("t_project"),
        },
        // {
        //   internal_name: "campaign",
        //   display_name: globalThis._lang("t_campaign"),
        // },
      ],
      alert_types: [
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        {
          internal_name: "project",
          display_name: globalThis._lang("t_project"),
        },
        {
          internal_name: "campaign",
          display_name: globalThis._lang("t_campaign"),
        },
      ],
      operands: [
        { internal_name: "=", display_name: globalThis._lang("t_equal") },
        { internal_name: "!=", display_name: globalThis._lang("t_notEqual") },
        { internal_name: "<", display_name: globalThis._lang("t_lessThan") },
        { internal_name: ">", display_name: globalThis._lang("t_greaterThan") },
        {
          internal_name: "<=",
          display_name: globalThis._lang("t_lessThanOrEqual"),
        },
        {
          internal_name: ">=",
          display_name: globalThis._lang("t_moreThanOrEqual"),
        },
      ],

      communication_types: [
        {
          internal_name: "notification", display_name: globalThis._lang("t_notification")
        },
        { internal_name: "mail", display_name: globalThis._lang("t_email") },
        {
          internal_name: "sms", display_name: globalThis._lang("t_sms")
        },
        {
          internal_name: "http", display_name: globalThis._lang("t_httpRequest")
        },
      ],
      alert_info: {
        display_name: "",
        alert_category: "agent",
        target_type: "",
        target_category: "status",
        target: "",
        operand: "=",
        value: '',
        communication_type: 'notification',
        communication_src: null,
        communication_destination: '',
        communication_text: '',
        inform_agent: false,
        speaker: 'both_of_them',
        period: 'automatic',
        period_value: 0,
        exceptions: '',
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n}`,
        }
      },
      isEdit: false,
      records: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "creator",
          label: globalThis._lang("t_username"),
          sortable: true,
        },
        {
          key: "create_date",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      speakers: [
        { internal_name: 'agent', display_name: globalThis._lang('t_agent') },
        { internal_name: 'customer', display_name: globalThis._lang('t_customer') },
        { internal_name: 'both_of_them', display_name: globalThis._lang('t_bothOfThem') }
      ],
      periods: [
        { internal_name: 'one_time', display_name: globalThis._lang('t_oneTime') },
        { internal_name: 'scheduled', display_name: globalThis._lang('t_scheduled') },
        { internal_name: 'automatic', display_name: globalThis._lang('t_automatic') }
      ],
    };
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  methods: {
    communication_type_change() {
      this.alert_info = {
        ...this.alert_info,
        communication_src: null,
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n}`,
        },
        communication_destination: '',
        communication_text: '',
        inform_agent: false,
      };
    },
    change_target_category() {
      this.alert_info.target = "";
    },
    set_target_types() {
      console.log("this.alert_info", this.alert_info);
      if (this.alert_info.alert_category == "queue") {
        this.target_types = this.queues;
      }
      if (this.alert_info.alert_category == "project") {
        this.target_types = this.projects;
      }
      if (this.alert_info.alert_category == "campaign") {
        this.target_types = this.campaigns;
      }
      this.alert_info.target_type = "";
    },
    updateSelected(item) {
      this.alert_info = {
        ...item,
      };
      if (this.alert_info.alert_category == "queue") {
        this.target_types = this.queues;
      }
      if (this.alert_info.alert_category == "project") {
        this.target_types = this.projects;
      }
      if (this.alert_info.alert_category == "campaign") {
        this.target_types = this.campaigns;
      }
      this.isEdit = true;
      this.modal_record_details = true;
    },

    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/alert/v1/Alert `)).data;

      this.records = response;
      this.clear_data();
      this.is_progress = false;
    },

    clear_data() {
      this.alert_info = {
        display_name: "",
        alert_category: "",
        target_type: "",
        target_category: "",
        target: "",
        operand: "=",
        value: '',
        communication_type: 'notification',
        communication_src: null,
        speaker: 'both_of_them',
        period: 'automatic',
        period_value: 0,
        exceptions: '',
        http_info: {
          type: "GET",
          url: "",
          body: `{\n}`,
          headers: `{\n}`,
        }
      };
    },
    confirmText(item) {
      this.alert_info = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteAlert();
        }
      });
    },
    setAlert: async function () {
      try {
        this.is_progress = true;
        let response;
        if (!this.isEdit) {
          response = (
            await this.$http_in.post(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert`,
              this.alert_info
            )
          ).data;
        } else {
          response = (
            await this.$http_in.put(
              `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert`,
              this.alert_info
            )
          ).data;
        }

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },
    deleteAlert: async function () {
      try {
        this.is_progress = true;
        let response = (
          await this.$http_in.delete(
            `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/alert/v1/Alert/${this.alert_info._id}`
          )
        ).data;

        if (response && response.acknowledged) {
          this.$swal({
            title: globalThis._lang("t_processSuccess"),
            text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.get_records();
        this.is_progress = false;
      }
    },

    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      this.agents = response;
    },

    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/spv/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`/spv/v1/SmsSettings`)).data;
      this.sms_settings = response;
    },

    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    GetAIProviders: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/AIProvider`)
      ).data;
      console.log("AIProvider", response);
      this.ai_providers = response;
    },

    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`spv/v1/FinishCodes`)).data;

      this.finish_codes = response;
    },
    GetStatus: async function () {
      var response = (await this.$http_in.get(`spv/v1/Status`)).data;

      this.statuses = response;
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },

    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response;
    },
  },

  mounted: async function () {
    this.is_progress = true;
    this.get_records();
    this.get_users();
    this.GetProjects();
    this.GetAIProviders();
    this.GetQueues();
    this.GetCampaigns();
    this.GetFinishCodes();
    this.GetStatus();
    this.GetEMailSettings();
    this.GetSmsSettings();

    this.is_progress = false;
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
